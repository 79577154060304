@import "type";

%section-pad {
  padding-left: var(--section-pad);
  padding-right: var(--section-pad);
  padding-top: calc(3 * var(--section-pad));
  padding-bottom: calc(3 * var(--section-pad));

  @media (min-width: 800px) {
    padding-top: var(--section-pad);
    padding-bottom: var(--section-pad);
  }
}

%subsection {
  border-top: 1px solid #9e9e9e;
  padding-top: 2rem;
}
