%h1 {
  font-family: var(--adelle);
  font-style: normal;
  font-weight: 600;
  font-size: var(--size-h1);
  line-height: 2;
  text-transform: uppercase;
}

%h2 {
  font-family: var(--adelle);
  font-style: normal;
  font-weight: 700;
  font-size: var(--size-h2);
  line-height: 1.09;
  text-transform: uppercase;
}

%h3 {
  font-family: var(--adelle);
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 1.33;
}

%body {
  font-family: var(--adelle);
  font-style: normal;
  font-weight: 300;
  font-size: var(--size-body);
  line-height: var(--line-body);
}

%bio {
  font-family: var(--adelle);
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 1.43;
}

%list {
  font-family: var(--adelle);
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 1.71;
}

%nav {
  font-family: var(--adelle);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.33;
  
  @media(min-width: 1024px) {
    font-size: 18px;
  }
}


// Adelle Sans Bold
// font-family: adelle-sans, sans-serif;
// font-weight: 700;
// font-style: normal;


// Adelle Sans Light
// font-family: adelle-sans, sans-serif;
// font-weight: 300;
// font-style: normal;


// Adelle Sans Regular
// font-family: adelle-sans, sans-serif;
// font-weight: 400;
// font-style: normal;


// Adelle Sans Semibold
// font-family: adelle-sans, sans-serif;
// font-weight: 600;
// font-style: normal;