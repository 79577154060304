@import "~scss/imports";

.element {
  @extend %list;

  > div + div {
    margin-top: 2rem;
  }
  
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
}

.heading {
  font-weight: bold;
}

.list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0.5rem;
  margin-bottom: 0;
  @media (min-width: 768px) {
    columns: 2;
  }
  @media (min-width: 1200px) {
    columns: 4;
  }
}
