@import "~scss/imports";

$sectionBreak: 1074px;

.elementInner {
  // display: grid;
  // grid-gap: 0;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  min-height: 66vh;
  position: relative;

  display: flex;
  flex-direction: column;;
  justify-content: center;
  align-items: flex-start;


  p {
    @extend %body;
  }

  @media (min-width: $sectionBreak) {
    min-height: 95vh;
  }

  @extend %section-pad;

  iframe {
    max-width: 100%;
    
    @media (min-width: $sectionBreak) {
      min-height: 66vh;
      height: -webkit-fill-available;
      width: 100%;
    }
  }
}

.heading {
  @extend %h2;
  z-index: 1;

  :global(.Section--blue) & {
    color: inherit;
  }
}

.inner {
  // margin-top: 1rem;
  position: relative;
  width: 100%;
  z-index: 3;

  @media (min-width: $sectionBreak) {
    margin-top: unset;
  }
}

.circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip: rect(auto,auto,auto,auto);
  z-index: 0;
  
  pointer-events: none;
} 

.span {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(70vh*0.66);
  padding-bottom: calc(70vh*0.66);
  border-radius: 50%;

  @media (min-width: $sectionBreak) {
    padding-bottom: calc(90vh*0.66);
    width: calc(90vh*0.66);
  }
}