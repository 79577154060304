@import "variables";

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: var(--adelle);
}
* {
  box-sizing: inherit;
}

.Section--lightBlue {
  background-color: var(--light-blue);

  aside > span {
    background-color: var(--white);
  }
}

.Section--blue {
  background-color: var(--blue);
  color: var(--white);

  aside > span {
    background-color: var(--medium-blue);
  }
}

.Section--mediumBlue {
  background-color: var(--medium-blue);
  color: var(--white);

  aside > span {
    background-color: var(--blue);
  }
}

.Section--yellow {
  background-color: var(--green);
  color: var(--black);

  aside > span {
    background-color: var(--white);
  }
}

.small {

  font-size: 28px !important;
  line-height: 40px !important;
}

.Section--white {
  background-color: var(--white);
  color: var(--black);

  aside > span {
    background-color: var(--white);
  }
}

[id] {
  &:before {
    content: "";
    display: block;
    margin-top: calc(-1 * var(--nav-height));
    height: var(--nav-height);
  }
}
