@import "~scss/imports";

$sectionBreak: 1200px;

.wrapper {
  background-image: url("/FH_YMM_texture_01.png");
  background-repeat: repeat;
  background-size: contain;
  position: absolute;
  opacity: 0.6;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  bottom: 0;
}

.element {
  @extend %section-pad;
  text-align: center;
  background-color: var(--medium-blue);
  background: linear-gradient(245deg, rgba(19,47,92,1) 0%, rgba(19,47,92,1) 48%, rgba(255,181,0,1) 100%);

  color: var(--white);
  padding-top: 3rem;
  padding-bottom: 3rem;
  min-height: 80vh;
  margin-top: var(--nav-height);

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 800px) {
    justify-content: flex-start;
  }
}

.cta {
  font-size: 20px;
  text-align: left;
  z-index: 8;

  a {
    font-size: 18px;
  }
}

.copy {
  @extend %body;
  margin: 0 0 1rem;

  @media (min-width: 800px) {
    margin: 0 0 1.5rem;
  }
}

.header {
  @extend %h1;
  line-height: 1.1 !important;
  position: relative;
  text-align: left;
  z-index: 3;

  @media (min-width: 800px) {
    // line-height: 1 !important;
  }
}

.embedWrap {
  max-width: 350px;
  
  @media (min-width: 800px) {
    max-width: 600px;
    
  }
}

.image {
  width: 100%;
  height: auto;
  top: calc(50% + var(--nav-height));
  position: absolute;
  transform: translateY(-50%);
  left: 0;
  padding: var(--section-pad);
  z-index: 3;
  opacity: 0.4;
}

.video {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.videoWrap {
  background-color: var(--black);
  position: absolute;
  left: 0;
  right: 0;
  // top: var(--nav-height);
  top: 140px;
  bottom: 60px;
  z-index: 0;

  > div {
    height: 100%;
  }

  @media (min-width: 768px) {
    bottom: 40px;
    top: 140px;
  }
  
  @media (min-width: 1200px) {
    top: 200px;

  }

  // &:before {
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   // background-color: var(--medium-blue);
  //   background: linear-gradient(245deg, rgba(19,47,92,1) 0%, rgba(19,47,92,1) 48%, rgba(255,181,0,1) 100%);
  //   opacity: 0.7;
  //   pointer-events: none;
  //   z-index: 2;
  // }
}

.circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip: rect(auto,auto,auto,auto);
  z-index: 0;
  pointer-events: none;
} 

.span {
  background: linear-gradient(45deg, rgba(19,47,92,1) 0%, rgba(19,47,92,1) 48%, rgba(255,181,0,1) 100%);
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(70vh*0.66);
  padding-bottom: calc(70vh*0.66);
  border-radius: 50%;

  @media (min-width: $sectionBreak) {
    padding-bottom: calc(90vh*0.66);
    width: calc(90vh*0.66);
  }
}

.embed {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
  }
}

.helper {
  color: var(--white);
  background-color: var(--black);
  padding: 10px var(--nav-pad);
  position: relative;
  text-align: center;

  @extend %bio;
  font-size: 14px;

  p {
    margin:0;
  }
}

.heading {
  @extend %h1;
}

.date {
  font-family: var(--adelle);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 2.33;
  text-align: center;
  letter-spacing: 0.11em;
}

.alert {
  background-color: var(--blue);
  color: var(--white);
  padding: 1rem var(--nav-pad);
  text-align: center;
  line-height: 1.5;
  font-family: var(--adelle);

  a {
    color: inherit;
  }
  button {
    font: inherit;
    background-color: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
  }
  a,
  button {
    font-weight: bold;
  }
}
