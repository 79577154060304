@import "~scss/imports";

.element {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  border: 2px solid;
  border-radius: 2rem;
  padding: 1rem 1.8rem;
  text-transform: uppercase;
  font-weight: 700;
  @extend %nav;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;

  &:global(.hover--green) {
    background-color: var(--blue);
    border-color: var(--blue);
    color: var(--white);
    
    &:hover,
    &:focus {
      background-color: var(--green);
      border-color: var(--green);
      color: var(--black);
    }
  }
  &:global(.hover--white) {
    background-color: var(--green);
    border-color: var(--green);
    color: var(--black);

    &:hover,
    &:focus {
      background-color: var(--white);
      border-color: var(--white);
      color: var(--black);
    }
  }
  &:global(.hover--blue) {
    background-color: var(--white);
    border-color: var(--white);
    color: var(--black);

    &:hover,
    &:focus {
      background-color: var(--blue);
      border-color: var(--blue);
      color: var(--white);
    }
  }
  
  &:global(.simple) {
    border: none;
    border-bottom: 1px solid white;
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    text-transform: none;
  }

  @media (min-width: 800px) {
    padding: 1rem 2.8rem;
  }
}
