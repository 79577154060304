@import "~scss/imports";

.element {
  padding: var(--nav-pad);
  min-height: 110px;
  background-color: var(--white);

  display: grid;
  grid-template-areas: "logo" "social" "copyright";
  grid-gap: 2rem;

  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  > a > img {
    width: auto;
    height: 50px;
  }
}

.social {
  grid-area: social;
  list-style-type: none;
  padding-left: unset;
  display: flex;
  margin-top: unset;
  margin-bottom: unset;

  li + li {
    margin-left: 2vw;
  }
}

.copyright {
  grid-area: copyright;
}

.logo {
  grid-area: logo;
}
