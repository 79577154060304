@import "~scss/imports";

.small > p {
    @media (min-width: 800px) {
        columns: 2;
        column-gap: 2em;

        font-size: 28px !important;
        line-height: 40px !important;
    }
}

.copy {
    // @extend %list;
    @extend %h3;
}

.bar {
    @extend %section-pad;
}