:root {
  --blue: #132F5C;
  --medium-blue: #181D30;
  --light-blue: #D3EAFD;
  --white: #fff;
  --green: #FFBE20;
  --black: #000;

  --section-pad: 20px;

  --size-h1: 72px;
  --size-h2: 18px;
  --size-body: 28px;

  --line-body: 40px;

  --domaine: "domaine-display-regular", serif;
  --adelle: "adelle-sans", sans-serif;

  --nav-height: 70px;
  --nav-pad: 40px;

  --button-pad: 3rem;

  @media (min-width: 600px) {
    --size-h2: 18px;
    --size-body: 48px;
    --line-body: 64px;
  }
  
  @media (min-width: 800px) {
    --size-h1: 120px;
    --nav-height: 100px;

    --section-pad: 50px;
  }
}
