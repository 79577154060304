.MobileNav {
  --toggle-size: 50px;
}
.MobileNav-toggleph {
  width: var(--toggle-size);
  height: var(--toggle-size);
  margin-left: 1em;
}
.MobileNavToggle {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;

  &:focus {
    outline: 2px solid var(--green);
  }

  .MobileNav.active & {
    position: fixed;
  }
  
  z-index: 1;
  width: var(--toggle-size);
  height: var(--toggle-size);
  padding: 7px;
}

.MobileNav-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;

  position: fixed;
  padding-top: var(--nav-height);
  margin-top: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - var(--nav-height));
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  background-color: var(--blue);
 
  li:first-child {
    margin-top: auto;
  }
  li:last-child {
    margin-bottom: auto;
  }

  li + li {
    margin-top: 2em;
  }
}

.MobileNav-links-item {
  &:hover,
  &:focus {
    border-bottom: 2px solid var(--green);
  }
}

.MenuIcon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  div {
    width: 100%;
    height: 3px;
    background-color: var(--white);
    //margin: calc(var(--toggle-size) / 5) 0;
    transition: opacity 0.2s, transform 0.3s;
    transform-origin: center center;
  }

  &.active {
    div:nth-child(2) {
      opacity: 0;
    }
    div:nth-child(1) {
      transform: translate(0px, calc(var(--toggle-size) / 4.5)) rotate(45deg);
    }
    div:nth-child(3) {
      transform: translate(0px, calc(-1 * var(--toggle-size) / 4))
        rotate(-45deg);
    }
  }
}
