@import "~scss/imports";

.element {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  align-items: center;
  background-color: var(--black);
  padding-left: var(--section-pad);
  padding-right: var(--section-pad);
  min-height: var(--nav-height);
  transition: background-color 0.3s;
  z-index: 10;
  color: var(--white);

  &:global(.dark) {
    background-color: var(--blue);
  }

  // max-width: 1600px;
  // margin-left: auto;
  // margin-right: auto;

  img {
    width: auto;
    height: 50px;

    @media (min-width: 800px) {
      height: 59px;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
    @extend %nav;
    cursor: pointer;
  }
}

.links {
  a + a {
    margin-left: 1rem;

    @media (min-width: 800px) {
      margin-left: 2vw;
    }
    @media (min-width: 1000px) {
      margin-left: 4vw;
    }
    @media (min-width: 1200px) {
      margin-left: 6vw;
    }
  }
}

.logo {
  z-index: 1;
}

.donate {
  background-color: var(--green) !important;
  color: var(--black) !important;
  font-weight: 700 !important;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  font-size: 18px !important;

  &:hover {
    background-color: var(--white) !important;
  }
  
  @media (min-width: 800px) {
    display: inline-block;
    border-radius: 50%;
    line-height: 85px !important;
    text-align: center;
    min-width: 85px;
  } 
}